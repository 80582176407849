import React, { useState } from 'react';


import { encodeTheUrl } from './shared';
import { variableRex, variableRex_plain } from './renderEngine';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid } from '@mui/material';


export function Introduction() {

    return <Accordion defaultExpanded={true}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <div style={{
                width: '100%',
                textAlign: "center"
            }} >
                <h1 style={{ textAlign: "center" }} >Templating with Table (Table Converter)</h1>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <div
            // style={{ width: '100%' }}
            >
                <Divider  />

                <Grid container spacing={0} margin={0}>

                    <Grid item xs={6} id="leftPanel">

                        <h2>Introduction:</h2>

                        <span>
                            It is about easy templating with data from table. <br />
                            There are two ways to use it: <br />
                            1. Batching: Paste example as template, introduce variables, then add inputs <br />
                            2. Converter: Upload CSV as variables and inputs, then adjust template<br /><br />
                            For output, preview the result, then export them to clipboard or download it (support zip)<br />
                        </span>

                    </Grid>
                    <Grid item xs={6} id="rightPanel">

                        <h3>Templating Syntax:</h3>
                        <span>
                            Define simple Variable accept input directly:
                            <strong>{'{{variableName}}'}</strong>

                            <br />

                            Define advanced Variable which value generated by Script:
                            <strong>{'{{variableName|script}}'}</strong>
                            <br />
                            It can rely on other variables, refer their value as <strong>{'<anotherVariableName>'}</strong> in the script, like
                            <br />
                            <i>{'{{variableName|\'prefix\' + <anotherVariableName> + \'suffix\'}}'}</i>
                            {/* <strong>{variableRex.toString()}</strong> */}
                            <br />
                            System Embedded Variables: <br />
                            <strong>{'_rowNo'}</strong> the No. of the row, start from 1 <br />
                            <strong>{'_isLastRow'}</strong> true or false, the last valid row
                            <br />
                        </span>
                        {/* consider this for edit regex https://github.com/pxpeterxu/react-regexr */}
                      
                        Advanced Cases:
                        <br />
                        <a href={encodeTheUrl(extractTemplate)} target='_blank'>Extract text from input by Regex </a> <br />
                        <a href={encodeTheUrl(groupIDsTemplate, null, false)} target='_blank'>Group Ids </a>
                        <br />
                    </Grid>


                </Grid>

            </div>
        </AccordionDetails>
    </Accordion>
}

const extractTemplate =
    "{{id|<_rowNo> + 1000}} {{lastName|<name>.match(/\\w+/g)[1]}} {{firstName|<name>.match(/\\w+/g)[0]}}"

const groupIDsTemplate =
    '{{id}}{{break|<_rowNo>%100==0 ? "" : (<_isLastRow> ? "" : ", ")}}'


//  {{abc}},{{m|<abc>.split(',').map(a=>"begin " + a + " end").join('\n')|}}