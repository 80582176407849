import React, { useImperativeHandle, useState } from 'react';

import {
    EmailIcon, EmailShareButton, FacebookIcon,
    FacebookShareButton,
    LinkedinIcon, LinkedinShareButton, TwitterIcon,
    TwitterShareButton
} from "react-share";

export const SocialShare = React.forwardRef((props, ref) => {

    const shareUrl = "https://temtool.com";
    const title = "easy templating with table!";

    return (<div style={{ width: "200px", margin: '1px', display: 'flex', justifyContent: 'space-around' }}>
    <FacebookShareButton
        url={shareUrl}
        quote={title}
        className="button"
    >
        <FacebookIcon
            size={32}
            round={false} />
    </FacebookShareButton>

    {/* <FacebookShareCount
        url={shareUrl}
        className="count">
        {count => count}
    </FacebookShareCount> */}

    <TwitterShareButton
        url={shareUrl}
        title={title}
        className="button">
        <TwitterIcon
            size={32}
            round={false} />
    </TwitterShareButton>

    <LinkedinShareButton
        url={shareUrl}
        title={title}
        windowWidth={750}
        windowHeight={600}
        className="button">
        <LinkedinIcon
            size={32}
            round={false} />
    </LinkedinShareButton>

    <EmailShareButton
        url={shareUrl}
        subject={title}
        body="body"
        className="button">
        <EmailIcon
            size={32}
            round={false} />
    </EmailShareButton>
    <br />
</div>)

})