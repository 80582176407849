import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

import Draft, {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    DraftDecorator,
    DraftHandleValue,
    EditorState,
    getDefaultKeyBinding,
    KeyBindingUtil,
    RawDraftEntityRange
} from "draft-js";
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import createMentionPlugin, {defaultSuggestionsFilter} from '@draft-js-plugins/mention';
import "@draft-js-plugins/mention/lib/plugin.css";
import EditorWithPlugins from '@draft-js-plugins/editor';
import "draft-js/dist/Draft.css";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import _, {DebouncedFunc} from 'lodash';
import {range} from 'lodash';
import React, {Component, createRef} from 'react';
import {withAlert} from 'react-alert';

import {extractText} from './draftjsUtil';
import './editorStyles.css';
import {Exporter} from "./exporter";
import {ExtractVariableButton} from './ExtractVariableButton';
import {ImportContext, Importer} from './importer';
import {Introduction} from "./intro";
import {
    calculateConcatenate,
    calculateSubstitute, genVariableHolder,
    initFixedVariables, inputNameExtract, isEmptyRow, PLACEHOLDER_COPY_NAME,
    preHandleTemplate, refreshVariables,
    RowNo,
    template_default, updateOutputOfGivenRow,
    VariableObject
} from './renderEngine';
import {encodeTheUrl} from './shared';
import {SocialShare} from './socialShare';

import {replaceAll} from './utils';

import {
    DataGridPro,
    GridApi,
    GridApiRef,
    GridCallbackDetails,
    GridCellEditCommitParams,
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnResizeParams,
    GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    MuiBaseEvent,
    MuiEvent,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';

//https://github.com/search?q=x-data-grid-pro+LicenseInfo&type=code
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {
    AppBar,
    Box,
    CssBaseline,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Slide,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import {TransitionProps} from '@mui/material/transitions';

LicenseInfo.setLicenseKey('403ecae9ce84c94e3c6117bcd098ff08T1JERVI6Mjg0MzcsRVhQSVJZPTE2NjA5OTUyMjUwMDAsS0VZVkVSU0lPTj0x')
//LicenseInfo.setLicenseKey("e3ec4d79d1fa1f36cc88ecffd4e68392T1JERVI6MzMyMjMsRVhQSVJZPTE2NjkzODUyMDIwMDAsS0VZVkVSU0lPTj0x");
//LicenseInfo.setLicenseKey('094c13fcff99f49fe015161354d1d052T1JERVI6MjkzMjgsRVhQSVJZPTE2NjMxMjQ0NjcwMDAsS0VZVkVSU0lPTj0x')

const firebaseConfig = {
    apiKey: "AIzaSyBzpty296j6_oN07mcLq0CnZmEOHghBenE",
    authDomain: "templating-tool.firebaseapp.com",
    databaseURL: "https://templating-tool.firebaseio.com",
    projectId: "templating-tool",
    storageBucket: "templating-tool.appspot.com",
    messagingSenderId: "916973673808",
    appId: "1:916973673808:web:57367309ebbe9f874ae055",
    measurementId: "G-8D4VNMK9XH"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// firebase.analytics();

const {
    convertFromRaw,
    convertToRaw,
    Editor,
    SelectionState,
    RichUtils,
    Modifier
} = Draft;

const {hasCommandModifier, isCtrlKeyCommand} = KeyBindingUtil;

const DEBUG_LOG = false

// const { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } = Menu;
const DEFAULT_OUTPUT_COLUMN = 'output_default'
const OUTPUT_HIDDEN_COLUMN = 'output_hidden'

const defaultColumnProperties = {
    resizable: true,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
};

const defaultParsePaste: (copiedTable: string) => string[][] =
    (str: string) => (
        str.split(/\r\n|\n|\r/)
            .map(row => row.split('\t'))
    );


var templateEditor: EditorWithPlugins | null

var templateTriggerId = 1

type RealData = {
    remoteSheetUrl: string | null;
    template: string;
    rawTemplate: string;
    variableObjects: VariableObject[];
    variables: string[];
    sampleOutput: string;
    variableAllSuggestions: any[];
}

type MyProps = {
    alert,
};

type ColumnDefinition = GridColDef & {
    variableObject?: VariableObject,
    index?: number
}

type MyState = {
    rows?: any[],
    topLeft?: any,
    botRight?: any,
    columns?: ColumnDefinition[],

    editorState?: EditorState,
    plugins?: any[],
    outputEditorState?: EditorState,

    suggestions?: any[],
    suggestionsOpen?: boolean,

    outputLineBreaker?: boolean,

    templateEditorDialogOpen?: boolean,
    inputEditorDialogOpen?: boolean,

};

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            {/* <GridToolbarFilterButton /> */}
            <GridToolbarDensitySelector/>
            {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
    );
}


class TableTemplate extends Component<MyProps, MyState> {

    editorStateDecorator?: DraftDecorator[]
    plugins?: any[]
    outputDecorators?: CompositeDecorator

    exporter: any
    importer: any

    inlineToolbarPlugin = createInlineToolbarPlugin();
    mentionPlugin = createMentionPlugin(
        //it will be better user can use @ without blank before it
        {
            // entityMutability: null,
            // mentionTrigger: '$',
            // supportWhitespace: true
        }
    );


    real: RealData = {
        remoteSheetUrl: null,
        template: "",
        rawTemplate: "",
        variableObjects: [],
        variables: [],
        sampleOutput: "",
        variableAllSuggestions: [],
    }

    state: MyState;

    templateFromUrl: string | null = null

    constructor(props) {
        super(props);

        //initial from URL
        let urlObj = new URL(window.location.href);

        this.templateFromUrl = urlObj.searchParams.get("template");
        console.log("template from url", this.templateFromUrl);

        let templateDefault = ""
        if (this.templateFromUrl) {
            templateDefault = this.templateFromUrl
        }

        this.real.remoteSheetUrl = urlObj.searchParams.get("sheetUrl")
        console.log("remoteSheetUrl from url", this.real.remoteSheetUrl);

        let outputLineBreaker = 'true' == (urlObj.searchParams.get("outputLineBreaker") || 'true').toLowerCase()
        console.log("outputLineBreaker from url", outputLineBreaker);

        let rows: any[] = []

        for (let step = 0; step < 20; step++) {
            this.addNewRow(rows)
        }

        this.real.template = templateDefault
        this.real.rawTemplate = templateDefault

        this.updateVariables(templateDefault, templateDefault, rows)
        let columns = this.updateInputTableColumns()

        this.real.template = preHandleTemplate(templateDefault)

        this.initialRowsWithDummyValue(rows)

        if (!this.plugins) {
            this.plugins = [
                this.inlineToolbarPlugin,
                this.mentionPlugin
            ]
        }

        this.state = {
            outputLineBreaker: outputLineBreaker,
        }

        this.state = {
            rows: rows,
            topLeft: {},
            botRight: {},
            columns: columns,
            editorState:
                EditorState.createWithContent(this.generateBlocks(templateDefault)),
            plugins: this.plugins,
            outputEditorState: this.generateOutputEditorState(rows),
            suggestions: this.real.variableAllSuggestions,
            outputLineBreaker: outputLineBreaker,

        };

    }

    initialRowsWithDummyValue = (rows: any[] = this.state.rows!) => {
        let index = 1;

        this.real.variableObjects.forEach(variableObject => {
            if (variableObject.allowInput) {
                rows[0][variableObject.inputName] = "REPLACE ME_" + index++;
                rows[1][variableObject.inputName] = "REPLACE ME_" + index++;
            }
        });

        this.updateOutputOfRow(rows[0]);
        this.updateOutputOfRow(rows[1]);

        this.refreshForLastRow(rows)
    }

    apiRef = createRef<GridApi>();
    // have to cast as any to work with class component, RefObject not compatible with RefMutableObject
    // function component use : useGridApiRef()
    getGridApi(): GridApi {
        return (this.apiRef).current as GridApi
    }


    componentDidMount() {

        //console.log("componentDidMount")

        if (!this.templateFromUrl) {
            this.importer.openQuickStartDialog()
        } else {
            //initial from csv
            if (this.real.remoteSheetUrl) {
                setTimeout(() => {
                    this.importer.loadRemoteSheet(this.real.remoteSheetUrl, this.templateChangeCallback)
                }, 100)

            }
        }

        this.getGridApi().subscribeEvent(
            'columnResize',
            (params: GridColumnResizeParams) => {
                console.log(
                    `Column ${params.colDef.headerName} resized to ${params.width}px.`,
                );
            },
        )

    }

    windowPushState(newRawTemplate = this.real.rawTemplate, newSheetUrl = this.real.remoteSheetUrl, newOutputLineBreaker = this.state.outputLineBreaker) {
        if (newRawTemplate) {
            window.history.pushState('', newRawTemplate, encodeTheUrl(newRawTemplate, newSheetUrl, newOutputLineBreaker))
        }
    }

    addCopyPasterListener(event: any = null) {
        // Copy paste event handler
        if (DEBUG_LOG) console.log("addCopyPasterListener")

        document.addEventListener('copy', this.handleCopy);
        document.addEventListener('paste', this.handlePaste);
    }

    removeCopyPasterListener(event: any = null) {
        if (DEBUG_LOG) console.log("removeCopyPasterListener")
        document.removeEventListener('copy', this.handleCopy);
        document.removeEventListener('paste', this.handlePaste);
    }

    alert(message: string) {
        this.props.alert.show(message);
    }

    extractVariable(replaceAllOfThem = false) {

        const editorState = this.state.editorState!

        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()

        const anchorKey = selection.getAnchorKey();
        const currentBlock = currentContent.getBlockForKey(anchorKey);

        const start = selection.getStartOffset();
        const end = selection.getEndOffset();
        const selectedText = currentBlock.getText().slice(start, end);

        const preSelect = currentBlock.getText().slice(0, start);

        //try extract the name from pre text
        let matched = preSelect.match(/[\w\d_\-]+/g)

        let defaultVariableName = "input_" + selectedText
        //Math.ceil(Math.random() * 100)

        if (matched) {
            defaultVariableName = matched[matched.length - 1]
        }

        const newVariableName = prompt('Please enter name for the input', defaultVariableName)

        if (newVariableName) {

            console.log('extractVariable', selectedText, 'as', newVariableName)

            const newVariableHolder = genVariableHolder(newVariableName);

            const rows = this.state.rows!;
            //populate the selected as variable value for the first row
            if (rows[0][newVariableName]) {
            } else {
                rows[0][newVariableName] = selectedText
            }

            let newContent

            if (!replaceAllOfThem) {
                //only replace selection
                newContent = Modifier.replaceText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    newVariableHolder
                );
            } else {
                //replace all
                newContent = this.generateBlocks(replaceAll(this.real.template, selectedText, newVariableHolder))
            }

            const newState = this.onRealTemplateChange(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            ));

            console.log("setState after extract", newState)

            this.setState(newState, () => {
                this.forceRender()
            });

        }

    }

    transformVariable(selectedVariable) {

        const editorState = this.state.editorState!

        const oldName = inputNameExtract(selectedVariable)

        const newName = `${oldName}_new|<${oldName}>`

        //console.log("xxx", oldName, newName)

        const newContent = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            newName)

        const newState = this.onRealTemplateChange(EditorState.push(
            editorState,
            newContent,
            'insert-characters'
        ));

        console.log("setState after transform", newState)

        this.setState(newState, () => {
            this.forceRender()
        });


    }

    forceRender() {

        console.log("force render")

        //templateEditor!.forceUpdate()
        //https://github.com/facebook/draft-js/issues/458

        var editorState = this.state.editorState!;

        let contentState = editorState.getCurrentContent();
        let decorator = editorState.getDecorator();

        let newState = EditorState.createWithContent(
            contentState,
            decorator
        );
        newState = EditorState.forceSelection(newState, editorState.getSelection())

        this.setState({editorState: newState})

    }

    private showSelection(selection: Draft.SelectionState) {
        const start = selection.getStartOffset();
        const end = selection.getEndOffset();

        console.log("selection", start, end);
    }

    handleTableUpload(oriData: any[]) {
        console.log("table upload:", oriData);

        if (!oriData || oriData.length == 0) {
            console.log("no data!")
            return
        }

        let data = [] as any[]

        // clone the data before update them
        for (let i = 0; i < oriData.length; i++) {
            data[i] = {...oriData[i]}
        }

        //generate variables from the first row
        //generate a simple template with all those variables


        // if key == DEFAULT_OUTPUT_COLUMN, then drop it
        data.forEach(row => {
            delete row[DEFAULT_OUTPUT_COLUMN]
            delete row[OUTPUT_HIDDEN_COLUMN]
        })

        //console.log("data", data)
        const rawKeys = Object.keys(data[0])

        const keys: string[] = []

        // key rename   
        rawKeys.forEach(key => {
            let newKey = this.adjustVariableName(key)

            data.forEach(row => {
                //TODO refactor this, multiple places have it
                row['_' + newKey] = row[key]
                delete row[key]
                //console.log(row)
            })

            keys.push(newKey)
        })

        initFixedVariables(keys)

        console.log("csv columns:", keys)

        let appendToTemplate = ""

        keys.forEach(key => {
            if (!this.real.variables.includes(genVariableHolder(key))) {
                appendToTemplate += genVariableHolder(key) + ","
            }
        })

        appendToTemplate.slice(0, appendToTemplate.length - 1)

        const rows = [] as any[]//this.state.rows!.slice(0)
        let index = 0
        data.forEach(newRow => {
            if (rows[index]) {
                rows[index] = {...rows[index], ...newRow}
            } else {
                this.addNewRow(rows, newRow)
            }
            // if (appendToTemplate.length == 0) {
            //     //there was a bug here, if no template change, then the output is not refreshed
            //     this.updateOutputOfRow(rows[index])
            // }
            index++
        })

        console.log("csv data:", data)

        this.alert(`CSV Loaded, totally ${data.length} rows`);

        for (let i = 0; i < 20 - data.length ; i++) {
            this.addNewRow(rows)
        }

        const keepTemplate = this.real.rawTemplate && this.real.rawTemplate.length > 0;

        if (!keepTemplate) {
            const newTemplate = keepTemplate ? this.real.rawTemplate : appendToTemplate;

            console.log("newTemplate", newTemplate)

            const newState = this.onRealTemplateChange(EditorState.push(
                this.state.editorState!,
                this.generateBlocks(newTemplate),
                'insert-characters'
            ), rows);

            console.log("setState after extract", newState)

            this.setState(newState);

        } else {
            rows.forEach((row) => {
                this.updateOutputOfRow(row)
            })

            this.refreshForLastRow(rows)

            console.log("no newTemplate");

            this.setState({
                rows: rows,
                outputEditorState: this.generateOutputEditorState(rows)
            });
        }

        /*
        if (appendToTemplate.length > 0) {

            let editorState = this.state.editorState!
            //append to the end
            editorState = EditorState.moveFocusToEnd(editorState)

            const newContent = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                appendToTemplate
            );

            editorState = EditorState.moveFocusToEnd(editorState)

            const newState = this.onRealTemplateChange(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            ));

            this.setState(newState)

            this.alert(`append ${appendToTemplate} to template`);

        } else {
            this.setState({ outputEditorState: this.generateOutputEditorState(rows) })
        }

        */
    }

    private adjustVariableName(key: string) {
        return key.replace(/[^_\-.a-zA-Z0-9]/g, "_");
    }

    generateOutputEditorState(rows: any[] = this.state.rows!) {
        return EditorState.createWithContent(this.generateOutputBlocks(rows), this.outputDecorators);
    }

    handleBeforeInput = (chars: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue => {
        // console.log("input", "[" + chars + "]")

        //{+{ => {{}}
        if (chars == "{") {

            const currentContent = editorState.getCurrentContent()
            const selection = editorState.getSelection()

            const anchorKey = selection.getAnchorKey();
            const currentBlock = currentContent.getBlockForKey(anchorKey);

            const start = selection.getStartOffset();
            const end = selection.getEndOffset();

            const preSelect = currentBlock.getText().slice(0, start);
            const afterSelect = currentBlock.getText().slice(end);

            let inserts: string | null = null
            if (chars == "{" && preSelect.endsWith("{")) {
                inserts = "{}}"
            }

            if (inserts) {
                const newContent = Modifier.insertText(
                    currentContent,
                    selection,
                    inserts
                );
                let newEditorState = EditorState.push(
                    editorState,
                    newContent,
                    'insert-characters'
                )
                const newSelection = new SelectionState({
                    anchorKey: anchorKey,
                    anchorOffset: start + 1,
                    focusKey: anchorKey,
                    focusOffset: start + 1,
                    isBackward: false,
                })
                newEditorState = EditorState.forceSelection(newEditorState, newSelection)

                this.setState({editorState: newEditorState})

                return "handled"
            }
        }

        return 'not-handled'
    }

    handlePastedText = (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
        //console.log("paste", "[" + text + "]")
        return 'not-handled'
    }

    focus = () => {

    }

    keyBindingFn = (e) => {
        //console.log("keyBindingFn", e)

        //TODO not so perfect, as without this, we don't know when cut: cmd+X
        //even with this, still had issue when user right click and select cut
        if (e.keyCode === 88 && (hasCommandModifier(e) || isCtrlKeyCommand(e))) {
            console.log("detect cut!")
        }
        return getDefaultKeyBinding(e);
    }

    handleKeyCommand = (command: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue => {
        // console.log("command", "[" + command + "]")
        return 'not-handled'
    }

    reflectTemplateChange = () => {
        //console.log(">>", "newState");

        const newState = this.onRealTemplateChange(this.state.editorState!);
        if (!_.isEmpty(newState)) {
            if (DEBUG_LOG) console.log("new state after reflect template change", newState)
            this.setState(newState, () => {
                // templateEditor!.forceUpdate()
                if ('plugins' in newState) {
                    this.forceRender()
                }
            });
        } else {
            if (DEBUG_LOG) console.log("state is empty", newState)
        }

        //TODO show an done ICON in UI?
        //console.log("<<", "newState");
    }

    updateTemplateTask?: DebouncedFunc<(x: any) => void>;

    onTemplateChange(editorState: EditorState) {

        // console.log(">>>>>>>>>>>>>>>>>>>")
        if (DEBUG_LOG) console.log(">>", "onTemplateChange triggered ", templateTriggerId++)

        //this.showSelection(editorState.getSelection());

        this.setState({
            editorState
        });

        if (this.updateTemplateTask) {
            this.updateTemplateTask.cancel();
        }

        this.updateTemplateTask = _.debounce(
            this.reflectTemplateChange, 400
        );

        //schedule it
        this.updateTemplateTask(null)

        // console.log("<<<<<<<<<<<<<<<<<<<")

    }

    onRealTemplateChange(oldEditorState: EditorState, rows: any[] = this.state.rows!): MyState {

        let newRawTemplate = extractText(oldEditorState);

        if (DEBUG_LOG) console.log(">>", "onRealTemplateChange:[" + newRawTemplate + "]")

        this.real.rawTemplate = newRawTemplate

        const newTemplateValue = preHandleTemplate(newRawTemplate)

        if (this.real.template == newTemplateValue) {
            if (DEBUG_LOG) console.log(" !", "template content no change")
            return {}
        }

        const oldTemplate = this.real.template;

        this.real.template = newTemplateValue

        this.windowPushState()

        console.log(" *", `template changed to:[${newTemplateValue}]`)

        const variablesUpdated = this.updateVariables(oldTemplate, newTemplateValue, rows)

        let newColumns = this.updateInputTableColumns()

        let expressionGood = true

        // if expression is not finished yet, don't run execute expression
        const errorInfo = this.updateOutputOfRow(rows[0]);

        if (errorInfo.length > 0) {
            expressionGood = false
        }

        // update all rows
        let newRows: any[] = []

        if (expressionGood) {
            rows.slice(0).forEach(row => {
                // need clone the row before update otherwise not rendered
                const newRow = {...row}
                this.addNewRow(newRows, newRow)

                this.updateOutputOfRow(newRow)

            })
        } else {
            newRows = rows
            this.alert(`expression error : ${errorInfo}`)
        }

        //set the last : _isLastRow (keyword TODO)
        this.refreshForLastRow(newRows);

        let newEditorState = this.removeMentionEntity(oldEditorState);

        //newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());

        if (variablesUpdated) {
            return {
                columns: newColumns,
                rows: newRows,
                editorState: newEditorState,
                plugins: this.plugins,
                outputEditorState: this.generateOutputEditorState(newRows),
                suggestions: this.real.variableAllSuggestions
            }
        } else {
            return {
                rows: newRows,
                //TODO this should not need, but there was bug, when using default template, the input columns are gone
                columns: newColumns,
                editorState: newEditorState,
                outputEditorState: this.generateOutputEditorState(newRows),
            }
        }
    }

    refreshForLastRow(newRows: any[]) {
        let theVariableObjects = this.real.variableObjects;
        if (theVariableObjects) {
            var foundLastRow = false;

            for (var i = newRows.length - 1; i >= 0; i--) {
                const theRow = newRows[i];

                if (!foundLastRow && !isEmptyRow(theVariableObjects, theRow)) {
                    theRow._isLastRow = true;
                    this.updateOutputOfRow(theRow);
                    //need a new object, otherwises, it will not reflected in the table (data grid)

                    // this spread had this error sometimes: TypeError: Cannot assign to read only property '0' of object '[object Array]'
                    // newRows[i] = { ...theRow }

                    foundLastRow = true;
                } else {
                    if (theRow._isLastRow) {
                        theRow._isLastRow = false;
                        this.updateOutputOfRow(theRow);
                        newRows[i] = {...theRow}
                    }
                }
            }
        }
    }

    private removeMentionEntity(newEditorState: Draft.EditorState): Draft.EditorState {
        const contentState = newEditorState.getCurrentContent();
        const selectionState = newEditorState.getSelection();
        const startKey = selectionState.getStartKey();
        const contentBlock = contentState.getBlockForKey(startKey);

        let entitySelection: any = null;

        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                if (entityKey) {
                    return contentState.getEntity(entityKey).getType() == 'mention';
                }
                return false;
            },
            (start, end) => {
                entitySelection = selectionState.merge({
                    anchorOffset: start,
                    focusOffset: end
                });
            });

        if (entitySelection) {
            console.log("revert mention entity", entitySelection)
            const newContentState = Modifier.applyEntity(
                contentState,
                entitySelection,
                null
            );

            newEditorState = EditorState.push(
                newEditorState,
                newContentState,
                'apply-entity'
            );

            newEditorState = EditorState.forceSelection(newEditorState, selectionState);
        }

        return newEditorState;
    }

    generateOutputDecorator(variableObjects: VariableObject[]): CompositeDecorator {
        let decorators: DraftDecorator[] = []
        variableObjects.forEach(variableObject => {
            decorators.push(
                {
                    strategy: function (contentBlock, callback, contentState) {
                        contentBlock.findEntityRanges(
                            (character) => {
                                const entityKey = character.getEntity();
                                if (entityKey === null) {
                                    return false;
                                }
                                //console.log("entityKey", entityKey, contentState.getEntity(entityKey) )
                                return contentState.getEntity(entityKey)["type"] == "VARIABLE_" + variableObject.variableIndex
                            },
                            callback
                        );
                    },
                    component: props => {
                        return (
                            <span data-offset-key={props.offsetkey} style={
                                {
                                    color: variableObject.color
                                }}>
                                {props.children}
                            </span>
                        );
                    },
                }
            )
        })
        return new CompositeDecorator(decorators);
    }

    generateDecorator(): DraftDecorator[] {
        //TODO no need create the decorators again every time
        let decorators: DraftDecorator[] = [
            {
                strategy: getEntityStrategy('IMMUTABLE'),
                component: props => {
                    return (
                        <span data-offset-key={props.offsetkey} style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            padding: '2px 0',
                        }}>
                            {props.children}
                        </span>
                    );
                },
            }
        ];

        //max 20 variables
        //this is pre-defined strategy
        //this will be also failed (loop setActive) if we only create the same amount decorator for each variableObject
        if (true) {
            const getVariableObjectsFun = () => this.real.variableObjects

            for (let i = 0; i < 20; i++) {
                decorators.push(
                    {
                        strategy: function (contentBlock, callback, contentState) {
                            const variableObjects = getVariableObjectsFun()
                            const variableObject = variableObjects[i];
                            //console.log("strategy", i, variableObject)

                            if (variableObject) {
                                ///\@[\w]+/g
                                findWithRegex(variableObject.regex, contentBlock, callback);
                            } else {
                                //nothing
                            }
                        },
                        component: props => {
                            const variableObjects = getVariableObjectsFun()
                            //console.log("component", i, variableObjects[i])

                            return (
                                <span data-offset-key={props.offsetkey} style={
                                    {
                                        color: variableObjects[i].color
                                    }}>
                                    {props.children}
                                </span>
                            );
                        },
                    }
                )
            }
        } else {

            // this is on demand strategy
            this.real.variableObjects.forEach(variableObject => {
                decorators.push(
                    {
                        strategy: function (contentBlock, callback, contentState) {
                            //console.log("strategy", variableObject)

                            ///\@[\w]+/g
                            findWithRegex(variableObject.regex, contentBlock, callback);
                        },
                        component: props => {
                            return (
                                <span data-offset-key={props.offsetkey} style={
                                    {
                                        color: variableObject.color
                                    }}>
                                    {props.children}
                                </span>
                            );
                        },
                    }
                )
            })
        }

        return decorators;
    }

    generateBlocks(text: string): ContentState {
        //generate the entityRanges

        let entityRanges: RawDraftEntityRange[] = []
        let entityMap = {}

        /* variable entity
        this.real.variables.forEach( variable => {
           entityMap[variable] = {
               type: "TOKEN",
               mutability: "MUTABLE"
           }
 
            let index = text.indexOf(variable)
            while (index >= 0) {
             entityRanges.push({ offset: index, length: variable.length, key: variable })
             index = text.indexOf(variable, index + 1)
            }
 
        })
        */

        entityMap['PLACEHOLDER'] = {
            type: "TOKEN",
            mutability: "IMMUTABLE"
        }
        let index = text.indexOf(PLACEHOLDER_COPY_NAME)
        while (index >= 0) {
            entityRanges.push({offset: index, length: PLACEHOLDER_COPY_NAME.length, key: index})
            index = text.indexOf(PLACEHOLDER_COPY_NAME, index + 1)
        }

        const blocks = convertFromRaw({
            blocks: [
                {
                    text: text,
                    type: "unstyled",
                    entityRanges: entityRanges,
                    key: "",
                    depth: 0,
                    inlineStyleRanges: []
                }],

            entityMap: entityMap
        });

        return blocks;
    }

    generateOutputBlocks(rows: any[]) {

        // right now, only ouput 20 rows to improve performance
        const outputPreviewOriginal = this.dumpOutput(rows.slice(0, 20), OUTPUT_HIDDEN_COLUMN)

        //####[[1]]####REPLACE ME 1####
        //TODO consider move this part to engine too
        const textBreaks = outputPreviewOriginal.split(/####/g);

        const entityMap = {}
        const entityRanges: RawDraftEntityRange[] = []

        this.real.variableObjects.forEach(variableObject => {
            entityMap[variableObject.variableIndex] = {
                //type will be used by strategy
                type: "VARIABLE_" + variableObject.variableIndex,
                mutability: "IMMUTABLE"
            }
        })

        let offset = 0

        for (let index = 0; index < textBreaks.length;) {

            const matchRes = textBreaks[index].match(/\[\[(\d+)\]\]/g)
            if (matchRes && matchRes.length == 1) {
                textBreaks.splice(index, 1)
                // get the color index
                const variableIndex = matchRes[0].slice(2, matchRes.length - 3)

                entityRanges.push({offset: offset, length: textBreaks[index].length, key: parseInt(variableIndex)})
            } else {
                offset += textBreaks[index].length
                index++

            }

        }

        let outputPreview = textBreaks.join("")

        //TODO for different row show differnt color
        const blocks = convertFromRaw({
            blocks: [
                {
                    text: outputPreview,
                    type: "unstyled",
                    entityRanges: entityRanges,
                    key: "",
                    depth: 0,
                    inlineStyleRanges: []
                }],

            entityMap: entityMap
        });

        return blocks;
    }

    updateVariables(oldTemplate: string, template: string, rows: any[] = this.state.rows!): boolean {

        const refreshedVariables = refreshVariables(oldTemplate, template, this.real.variables, rows)

        if (refreshedVariables) {

            console.log("refreshedVariables")

            this.real.variables = refreshedVariables.variables
            this.real.variableObjects = refreshedVariables.variableObjects
            this.real.variableAllSuggestions = refreshedVariables.variableObjects.map(o => {
                return {name: `{{${o.columnName}}}`}
            });

            if (DEBUG_LOG) console.log("xxx", this.real.variableObjects)
            if (DEBUG_LOG) console.log("xxx", this.real.variableAllSuggestions)

        }

        if (!this.plugins || refreshedVariables) {

            this.editorStateDecorator = this.generateDecorator();
            this.outputDecorators = this.generateOutputDecorator(this.real.variableObjects);

            // https://medium.com/@gauravsobti1/draft-js-decorator-not-working-with-plugins-47c02763442a

            const variableDecoratorPlugin = {
                decorators: this.editorStateDecorator
            };

            this.plugins = [
                this.inlineToolbarPlugin,
                this.mentionPlugin,
                variableDecoratorPlugin
            ]
        }

        return refreshedVariables !== null

    }

    updateInputTableColumns() {

        // update columns
        //TODO define column type
        let columns: ColumnDefinition[] = []

        const columnsDefinitions = this.real.variableObjects.slice()

        columnsDefinitions.splice(0, 0, {
            inputName: RowNo,
            columnName: "Row No.",
            allowInput: false,
            variableIndex: -1,
            full: "row No",
            variableName: "",
            color: 'rgb(128, 128, 128)',
            regex: RegExp(""),
            expression: "",
            rawExpression: "",
            dependsOnInputs: [],
            getValue: () => null

        })


        columnsDefinitions.forEach(variableObject => {
            columns.push(
                {
                    variableObject: variableObject,

                    //fields for MUI data grid
                    field: variableObject.inputName,
                    headerName: variableObject.columnName,
                    editable: variableObject.allowInput,

                    renderCell: (params: GridRenderCellParams<string>) => (
                        <div style={
                            {
                                color: variableObject.color,
                                // backgroundColor: variableObject.allowInput ? undefined : 'rgba(0, 0, 0, 0.05)'
                            }
                        }>
                            {params.value}
                        </div>
                    ),
                    headerAlign: 'center',

                    flex: variableObject.variableIndex < 0 ? 0.5 : 1,
                    disableReorder: variableObject.variableIndex < 0,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        let column = params.colDef as any
                        let variableObject = column.variableObject

                        return <div style={
                            {
                                width: "100%",

                                textAlign: 'center',
                                //   borderColor: 'blue',
                                //   borderWidth: '5px 0 0 0',
                                //   borderStyle: 'solid',
                                backgroundColor: variableObject.allowInput ? undefined : 'rgba(0, 0, 0, 0.05)'
                            }
                        }>

                            <strong>{column.variableObject.columnName}</strong>
                            {variableObject.expression &&

                                <span style={{color: 'gray', fontSize: 'smaller'}}>
                                    <br/>
                                    {column.variableObject.rawExpression}
                                </span>
                            }

                        </div>


                    },
                }
            )
        })

        columns.push(
            {
                //fields for MUI data grid
                field: DEFAULT_OUTPUT_COLUMN,
                headerName: 'Output',
                editable: false,
                renderHeader: (params: GridColumnHeaderParams) => {
                    return <div style={
                        {
                            width: "100%",
                            textAlign: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)'
                        }
                    }>
                        <strong>{"Output"}</strong>
                    </div>


                },
            }
        )

        //TODO need enable this in case of we have colorful output
        columns.push(
            {
                //fields for MUI data grid
                field: OUTPUT_HIDDEN_COLUMN,
                editable: false,
                hide: true
            }
        )

        //console.log(columns.length)

        return columns.map(c => ({...c, ...defaultColumnProperties}));

    }

    componentWillUnmount() {
        this.removeAllListeners();
    }

    removeAllListeners() {
        this.removeCopyPasterListener();
    }

    addNewRow(rows: any[], newrow = {}) {
        newrow[RowNo] = rows.length + 1
        newrow['id'] = rows.length + 1
        //console.log("addNewRow", newrow)
        rows.push(newrow)
    }

    updateRows = (startIdx, newRows: any[]) => {
        console.log("update rows")

        this.setState((state) => {
            const rows = state.rows!.slice();

            //add more rows in case
            //push one more rows in for edit

            let toBeAddedRowNum = startIdx + newRows.length + 2 - rows.length
            console.log("toBeAddedRowNum:" + toBeAddedRowNum)
            if (toBeAddedRowNum > 0) {
                Array.from(Array(toBeAddedRowNum)).forEach((x, i) => {
                    this.addNewRow(rows)
                });
            }

            for (let i = 0; i < newRows.length; i++) {
                if (startIdx + i < rows.length) {
                    rows[startIdx + i] = {...rows[startIdx + i], ...newRows[i]};
                    this.updateOutputOfRow(rows[startIdx + i])
                }
            }

            this.refreshForLastRow(rows);

            return {
                rows: rows,
                outputEditorState: this.generateOutputEditorState(rows)
            };
        });
    }

    handleCopy = (e) => {
        console.info('handleCopy Called');
        e.preventDefault();
        const {topLeft, botRight} = this.state;

        const allColumns = this.getGridApi().getVisibleColumns();

        const rows = this.state.rows!

        // Loop through each row
        const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(
            // Loop through each column
            rowIdx => allColumns!.slice(topLeft.colIdx, botRight.colIdx + 1).map(
                // Grab the row values and make a text string
                col => rows[rowIdx][col.field],
            ).join('\t'),
        ).join('\n');
        console.info('text', text);
        e.clipboardData.setData('text/plain', text);
    }

    handlePaste = (e) => {
        console.info('handlePaste Called');
        e.preventDefault();
        const {topLeft} = this.state;

        const newRows: any[] = [];
        const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));

        //console.info('pasteData', pasteData);
        const allColumns = this.getGridApi().getVisibleColumns();

        const allowInputs: boolean[] = []
        for (let index = 0; index < allColumns.length; index++) {
            const columnDef = allColumns[index]
            allowInputs[index] = (this.state.columns?.find(cd => cd.field == columnDef.field)?.variableObject?.allowInput) ?? false;
        }

        // console.log("xxx", allowInputs)

        pasteData.forEach((row) => {
            const rowData: any = {};
            // Merge the values from pasting and the keys from the columns
            for (let j = 0; j < row.length; j++) {
                const columnDef = allColumns[topLeft.colIdx + j];

                // consider case of not allowed input column
                if (allowInputs[topLeft.colIdx + j]) {
                    rowData[columnDef.field] = row[j];
                }
            }

            // Push the new row to the changes
            newRows.push(rowData)
        });

        //console.info('newRows', newRows);

        this.updateRows(topLeft.rowIdx, newRows);
    }

    onGridRowsUpdated = ({fromRow, toRow, updated, action}) => {
        console.info('onGridRowsUpdated!', action);
        console.info('updated', updated);
        console.info('fromRow toRow', fromRow, toRow);

        if (action !== 'COPY_PASTE') {
            this.setState((state) => {
                const rows = state.rows!.slice();
                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = {...rows[i], ...updated};
                    // update the output
                    this.updateOutputOfRow(rows[i]);
                    //if last row, then add 2 more new row
                    if (i == rows.length - 1) {
                        this.addNewRow(rows)
                        this.addNewRow(rows)
                    }

                }

                this.refreshForLastRow(rows)

                return {
                    rows: rows,
                    outputEditorState: this.generateOutputEditorState(rows)
                };
            });
        }
    };

    setSelection = (args) => {
        console.log("setSelection", args)
        this.setState({
            topLeft: {
                rowIdx: args.topLeft.rowIdx,
                colIdx: args.topLeft.idx,
            },
            botRight: {
                rowIdx: args.bottomRight.rowIdx,
                colIdx: args.bottomRight.idx,
            },
        });
    };

    updateOutputOfRow(row: any): string {
        return updateOutputOfGivenRow(this.real.template, this.real.variableObjects, row, [
            {
                columnName: DEFAULT_OUTPUT_COLUMN
            },
            {
                columnName: OUTPUT_HIDDEN_COLUMN,
                variableValuePrefix: "####[[_index_]]####",
                variableValueSuffix: "####"
            }
        ])
    }

    dumpOutput(rows = this.state.rows!.slice(0), outputColumn = DEFAULT_OUTPUT_COLUMN) {

        let dump = ""
        rows.forEach((row) => {
            let output = row[outputColumn]
            if (output) {
                //console.log(output)
                if (!this.state || this.state.outputLineBreaker) {
                    dump += output + "\n"
                } else {
                    dump += output
                }
            }

        })

        return dump;
    }

    outputToText = () => {
        const outputColumn = DEFAULT_OUTPUT_COLUMN;
        const selectedRowIds = this.getSelectRowIds()

        const rows = this.state.rows!
            .filter(row => row[outputColumn] && (selectedRowIds.length == 0 || selectedRowIds.includes(row["id"])))
        const text = this.dumpOutput(rows, outputColumn);
        return {text, rows};
    }

    outputToSheet = (includeInput: boolean = true): any[] => {
        const outputColumn = DEFAULT_OUTPUT_COLUMN;
        //copy the rows
        let validFields = includeInput ? this.real.variableObjects.map(vo => vo.inputName) : []
        validFields.push(DEFAULT_OUTPUT_COLUMN);

        const selectedRowIds = this.getSelectRowIds()
        const rows = this.state.rows!
            .filter(row => row[outputColumn] && (selectedRowIds.length == 0 || selectedRowIds.includes(row["id"])))
            .map(row => {
                const newRow = {...row};
                Object.keys(newRow).forEach(key => {
                    if (!validFields.includes(key)) {
                        delete newRow[key];
                    }
                });
                return newRow;
            });

        return rows;
    }

    getSelectRowIds(): number[] {
        const keysIter = this.getGridApi().getSelectedRows().keys();

        let iterResult = keysIter.next()
        const keys: number[] = []

        while (!iterResult.done) {
            keys.push(iterResult.value as number)
            iterResult = keysIter.next()
        }

        return keys
    }

    clearInputs() {
        const rows = this.state.rows!
        const newRows: any[] = []

        const keys = this.getSelectRowIds()

        let newIdCounter = 1

        rows.forEach(row => {
            if (!keys.includes(row["id"])) {
                newRows.push(row)
                row["id"] = newIdCounter
                row[RowNo] = newIdCounter
                newIdCounter++
            } else {
                this.getGridApi().selectRow(row["id"], false)
            }
        });

        // make sure we still have enough rows for edit
        for (let i = newIdCounter; i < 20; i++) {
            this.addNewRow(newRows)
        }

        this.setState({
            rows: newRows,
            outputEditorState: this.generateOutputEditorState(newRows)

        })

    }

    clearAllRows() {
        const rows = []


        this.addNewRow(rows)
        this.addNewRow(rows)

        this.setState({
            rows,
            outputEditorState: this.generateOutputEditorState(rows)

        })
    }


    onSearchChange = ({value}) => {

        //console.log("onSearchChange", value)

        this.setState({
            suggestions: defaultSuggestionsFilter(value,
                this.real.variableAllSuggestions),
        });
    };

    onAddMention = (mention) => {
        // get the mention object selected
        console.log("add mention", mention)
    }

    templateChangeCallback = (newTemplate) => {
        console.log("templateChangeCallback", newTemplate)

        if (newTemplate) {
            this.updateVariables(this.real.template, newTemplate);

            if (template_default == newTemplate) {
                this.initialRowsWithDummyValue();
            }
            this.setState(
                this.onRealTemplateChange(EditorState.createWithContent(this.generateBlocks(newTemplate)))
            );
        } else {
            this.setState(
                this.onRealTemplateChange(this.state.editorState!)
            );
        }
    }

    importContext: ImportContext = {
        handleTableUpload: (data: any[], remoteUrl: string | null) => {
            this.handleTableUpload(data)
            if (remoteUrl) {
                this.real.remoteSheetUrl = remoteUrl!
            }
        },
        templateChangeCallback: this.templateChangeCallback,
        getTemplate: () => {
            return this.real.rawTemplate
        },
    }

    goToLoadSheetDialog = () => {
        this.importer.openImportDialog()
    }

    goToExportDialog = () => {

        this.exporter.openExport(
            this.outputToText,
            this.outputToSheet,
            (placeHolderForRowNo: string) => {
                if (true) {
                    return calculateSubstitute(this.real.template, this.real.variableObjects, placeHolderForRowNo)
                } else {
                    //TODO give option to user
                    return calculateConcatenate(this.real.template, this.real.variableObjects, placeHolderForRowNo)

                }
            });
    }


    render() {

        const {InlineToolbar} = this.inlineToolbarPlugin;

        const {MentionSuggestions} = this.mentionPlugin;

        const templateEditorComponment = (

            <div className="editor"
                // onClick={this.focus}
                // NOTE: if enabled scroll , the inline toolbar will be showed in wrong position
                // style={{
                //     minHeight: "100px",
                //     maxHeight: "300px",
                //     overflowY: "scroll"
                // }}
            >
                <EditorWithPlugins
                    editorState={this.state.editorState!}
                    plugins={this.state.plugins!}

                    onChange={(editorState) => this.onTemplateChange(editorState)}

                    handleBeforeInput={this.handleBeforeInput}
                    // handlePastedText={this.handlePastedText}
                    // handleKeyCommand={this.handleKeyCommand}
                    // keyBindingFn={this.keyBindingFn}

                    placeholder="Fill your template here"

                    ref={c => (templateEditor = c)}

                />

                <MentionSuggestions
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions!}
                    onAddMention={this.onAddMention}
                    open={this.state.suggestionsOpen || false}
                    onOpenChange={(open: boolean) => {
                        this.setState({
                            suggestionsOpen: open
                        })
                    }}
                />

                <InlineToolbar>
                    {
                        (externalProps) => (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0.5}
                                margin={"5px"}
                            >
                                <ExtractVariableButton {...externalProps}
                                                       extractVariable={
                                                           (replaceAllOfThem) => this.extractVariable(replaceAllOfThem)
                                                       } transformVariable={
                                    (selectedVariable) => this.transformVariable(selectedVariable)
                                }
                                />

                            </Stack>
                        )
                    }
                </InlineToolbar>
            </div>
        )

        const inputEditorComponent = (
            <div>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                    margin={"5px"}
                >

                    <Button variant="outlined" onClick={() => this.clearInputs()} color="secondary">
                        Delete Selected Rows
                    </Button>

                    <span>💡: Copy Paste cells directly from Excel/Sheet</span>

                    <Button variant="contained" onClick={this.goToLoadSheetDialog} color="primary">
                        Load from sheet
                    </Button>


                </Stack>

                <div
                    // style={{ height: "100%", width: "100%", overflowY: "scroll", padding: '2px', marginBottom: '10px' }}
                    // TODO those two are triggered multiple times
                    onBlur={(event) => this.removeCopyPasterListener(event)}
                    onFocus={(event) => this.addCopyPasterListener(event)}
                >
                    <DataGridPro
                        columns={this.state.columns!}
                        rows={this.state.rows!}
                        apiRef={this.apiRef as any}
                        hideFooter={true}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                        checkboxSelection={true}
                        disableSelectionOnClick={true}
                        // isRowSelectable={(params: GridRowParams) => false}
                        onCellClick={
                            (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
                                if (DEBUG_LOG) console.log("onCellClick", params)

                                let colIdx = this.getGridApi().getColumnIndex(params.field, true)

                                this.setState({
                                    topLeft: {
                                        rowIdx: (params.id as number) - 1,
                                        colIdx: colIdx,
                                    },
                                    botRight: {
                                        rowIdx: (params.id as number) - 1,
                                        colIdx: colIdx,
                                    },
                                });
                            }
                        }

                        onCellEditCommit={(params: GridCellEditCommitParams,
                                           event: MuiEvent<MuiBaseEvent>,
                                           details: GridCallbackDetails) => {
                            console.log("onCellEditCommit", params)

                            this.setState((state) => {
                                const rows = state.rows!.slice();
                                let i = (params.id as number) - 1
                                rows[i] = {...rows[i]}
                                rows[i][params.field] = params.value
                                // update the output
                                this.updateOutputOfRow(rows[i]);
                                //if last row, then add 2 more new row
                                if (i == rows.length - 1) {
                                    this.addNewRow(rows)
                                    this.addNewRow(rows)
                                }

                                this.refreshForLastRow(rows)

                                return {
                                    rows: rows,
                                    outputEditorState: this.generateOutputEditorState(rows)
                                };
                            });
                        }
                        }

                        // headerHeight={100}
                    />


                </div>
            </div>
        )

        //console.log("plugins", this.state.plugins)

        return (
            <div>

                <div>

                    <Exporter
                        ref={node => (this.exporter = node)}
                    />
                    <Importer
                        ref={node => {
                            if (node) {
                                this.importer = node;
                                this.importer.init(this.importContext)
                            }
                        }}
                    />
                </div>


                {this.state.templateEditorDialogOpen && <Dialog
                    fullScreen
                    open={this.state.templateEditorDialogOpen}
                    onClose={() => {
                        this.setState({
                            templateEditorDialogOpen: false
                        })
                    }}
                >
                    <AppBar sx={{position: 'relative'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    this.setState({
                                        templateEditorDialogOpen: false
                                    })
                                }}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                Template Editor
                            </Typography>
                            <Button autoFocus color="inherit" onClick={() => {
                                this.setState({
                                    templateEditorDialogOpen: false
                                })
                            }}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>

                    {templateEditorComponment}
                </Dialog>
                }

                {this.state.inputEditorDialogOpen && <Dialog
                    fullScreen
                    open={this.state.inputEditorDialogOpen}
                    onClose={() => {
                        this.setState({
                            inputEditorDialogOpen: false
                        })
                    }}
                >
                    <AppBar sx={{position: 'relative'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    this.setState({
                                        inputEditorDialogOpen: false
                                    })
                                }}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                Input Editor
                            </Typography>
                            <Button autoFocus color="inherit" onClick={() => {
                                this.setState({
                                    inputEditorDialogOpen: false
                                })
                            }}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div style={{
                        margin: "15px"
                    }}>
                        {inputEditorComponent}
                    </div>
                </Dialog>
                }

                <Box
                    sx={{
                        backgroundColor: '#D3D3D3',

                    }}
                >

                    <Grid container spacing={0} margin={0} padding={1}>

                        <Grid item xs={12} id="introPanel">
                            <Introduction/>
                        </Grid>

                        <Grid item xs={6} id="inputPanel" margin={0}>
                            <Paper>
                                <Box sx={{margin: 1}}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={0.5}
                                    >
                                        <h2>Inputs:</h2>
                                        <IconButton onClick={() => {
                                            this.setState({
                                                inputEditorDialogOpen: true
                                            })
                                        }}><FullscreenIcon/></IconButton>

                                    </Stack>

                                    {!this.state.inputEditorDialogOpen && inputEditorComponent}

                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={6} id="templateAndResultPanel">

                            <Grid container direction="column" spacing={0} margin={0} paddingLeft={1}>

                                <Grid item id="templatePanel">
                                    <Paper>
                                        <Box sx={{margin: 1, padding: 0}}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                                spacing={0}
                                            >
                                                <h2>Convert Template:</h2>
                                                <IconButton onClick={() => {
                                                    this.setState({
                                                        templateEditorDialogOpen: true
                                                    })
                                                }}><FullscreenIcon/></IconButton>

                                            </Stack>


                                            {!this.state.templateEditorDialogOpen && templateEditorComponment
                                            }
                                            <span>💡: Select text to quick create Variable / Type '<b>@</b>' to quick insert existing Variable</span>

                                            <br/><br/>

                                        </Box>
                                    </Paper>

                                </Grid>

                                <Grid item id="resultPanel">
                                    <Paper>
                                        <Box sx={{padding: 1}}>

                                            <h2 style={{
                                                margin: '2px',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                                Outputs:

                                                <div>
                                                    <FormControlLabel
                                                        label="with line breaker"
                                                        control={<Checkbox checked={this.state.outputLineBreaker}
                                                                           onChange={
                                                                               (event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                   let value = event.target.checked;
                                                                                   this.state.outputLineBreaker = value
                                                                                   this.windowPushState()
                                                                                   this.setState({
                                                                                       outputEditorState: this.generateOutputEditorState(),
                                                                                       outputLineBreaker: value
                                                                                   })
                                                                               }
                                                                           } color="primary"/>}
                                                    />

                                                    <Button variant="contained" onClick={this.goToExportDialog}
                                                            color="primary">
                                                        Export \ Download
                                                    </Button>
                                                </div>
                                            </h2>

                                            Totally: {this.state.rows!.filter(row => row[DEFAULT_OUTPUT_COLUMN]).length} Rows
                                            (only preview first 20 rows)
                                            <br/>
                                            <div style={{
                                                border: "1px solid #ccc",
                                                cursor: "text",
                                                minHeight: 80,
                                                padding: 10,
                                                height: "500px",
                                                overflowY: "scroll"
                                            }}
                                            >
                                                <Editor
                                                    editorState={this.state.outputEditorState!}
                                                    readOnly={true}
                                                    onChange={outputEditorState => {
                                                        this.setState({outputEditorState});
                                                    }}
                                                />

                                            </div>

                                        </Box>

                                    </Paper>
                                </Grid>

                                <Grid item id="shareAndFeedback">
                                    <Paper>
                                        <Box sx={{margin: 1, padding: 1}}>
                                            <SocialShare/>

                                            <a href="https://groups.google.com/forum/#!forum/templating-tool"
                                               target='_blank'>Open Google Groups to give feedbacks</a>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Grid>


                    </Grid>

                </Box>


            </div>

        );
    }

}


function getEntityStrategy(mutability) {
    return function (contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getMutability() === mutability;
            },
            callback
        );
    };
}

function findWithRegex(regex: RegExp, contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

export default withAlert()(TableTemplate);