import React, { Component } from 'react';

import {
    listAllVariablesIncludeNameHolder,
    variableRex,
} from './renderEngine';

import { occurrences } from './utils';
import { extractText } from './draftjsUtil';


//TODO can refactor this
//refer to https://github.com/draft-js-plugins/draft-js-plugins/blob/master/draft-js-buttons/src/utils/createBlockStyleButton.js
// this.props.setEditorState(
//     RichUtils.toggleBlockType(this.props.getEditorState(), blockType)
//   );
//refer https://github.com/facebook/draft-js/blob/master/src/model/modifier/RichTextEditorUtil.js
export class ExtractVariableButton extends Component {

    onMouseDown = (event) => event.preventDefault()

    render() {
        // check whether we should show the button or not
        const editorState = this.props.getEditorState()

        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()

        //console.log('extractVariable', currentContent, selection)

        const anchorKey = selection.getAnchorKey();
        const currentBlock = currentContent.getBlockForKey(anchorKey);

        const start = selection.getStartOffset();
        const end = selection.getEndOffset();

        if (start == end) {
            return null
        }

        const currentLine = currentBlock.getText();

        const variables = listAllVariablesIncludeNameHolder(currentLine)

        var checkIndex = 0

        var selectedVariable = null;

        for (let i = 0; i < variables.length; i++) {
            const v = variables[i]
            const rangeFrom = currentLine.indexOf(v, checkIndex)
            const rangeTo = rangeFrom + v.length
            if (
                start == rangeFrom + 2 && end == rangeTo - 2
            ) {
                selectedVariable = v;
            } else if (start > rangeFrom && end < rangeTo) {
                return null;
            }
            //if either start or end fall in rangeFrom and rangeTo, then not valid
            else if (
                (start >= rangeFrom && start < rangeTo)
                || (end > rangeFrom && end <= rangeTo)
            ) {
                //console.log("not valid for extract", start, end, rangeFrom, rangeTo)
                return null;
            }
            checkIndex = rangeTo
        }

        const selectedText = currentLine.slice(start, end)

        const template = extractText(editorState);
        const occurrenceNum = occurrences(template.replace(variableRex, ""), selectedText)

        return (
            <React.Fragment>
                {selectedVariable && (
                    <div onMouseDown={this.onMouseDown} className="extractButtonWrapper">
                        <button onClick={
                            () => setTimeout(() => {
                                this.props.transformVariable(selectedVariable)
                            }, 100)
                        } className="extractSmallButton">
                            Transform this
                        </button>
                    </div>
                )}
                {!selectedVariable && (
                    <div onMouseDown={this.onMouseDown} className="extractButtonWrapper">
                        <button onClick={
                            () => setTimeout(() => {
                                this.props.extractVariable(false)
                                //TODO without timeout, the editorstate will be failed to update!
                            }, 100)
                        } className="extractSmallButton">
                            Extract This
                        </button>
                    </div>
                )}
                {!selectedVariable && occurrenceNum >= 2 && (
                    <div onMouseDown={this.onMouseDown} className="extractButtonWrapper">
                        <button onClick={
                            () => setTimeout(() => {
                                this.props.extractVariable(true)
                            }, 100)
                        } className="extractSmallButton">
                            Extract All:{occurrenceNum}
                        </button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}