import { getFixedColor, evalInContext, escapeRegExp } from './utils';

import { isEqual } from 'lodash';

// const Handlebars = require("handlebars");

export const RowNo = "_rowNo"

export const variableRex = /{{([0-9a-zA-Z\-_]+)(\|.*?)?}}/g

//If you want to not include it but you still require a conditional group, use a non-capturing group: (?:a). 
//The questionmark-colon can be used inside any capture group and it will be omitted from the resulting list of captures.
export const variableRexForSplit = /({{[0-9a-zA-Z\-_]+(?:\|.*?)?}})/g

export const variableRex_plain = /{{[0-9a-zA-Z\-_]+}}/g

export const PLACEHOLDER_COPY_NAME = "[[COPY_NAME]]"
export const variableWithHolderRex = new RegExp("(" + escapeRegExp(PLACEHOLDER_COPY_NAME) + ")|(" + variableRex.source + ")", "g")

export const template_default = "Paste Or Create your template here like If {{input_1}}, then {{input_2}}, else {{input_3}}"

export function renderText(text: string, row: any, variableObject: VariableObject, variableValuePrefx?: string, variableValueSuffix?: string) {

    let replaced = variableObject.getValue(row)

    if (!replaced) {
        //TODO let user configure it
        replaced = ""
    }

    if (variableValuePrefx) {
        variableValuePrefx = variableValuePrefx.replace("_index_", variableObject.variableIndex.toString())

        replaced = variableValuePrefx + replaced + variableValueSuffix
    }

    //console.debug("renderText before replace", text, variableObject, replaced)

    return unescapeVariable(text.replace(variableObject.regex, replaced))
}


function variableNameExtract(fullVariable: string) {

    return fullVariable.slice(2, fullVariable.length - 2)

}

export function inputNameExtract(fullVariable: string) {
    if (fullVariable.indexOf("|") >= 0) {
        return fullVariable.slice(2, fullVariable.indexOf("|"))
    } else {
        return fullVariable.slice(2, fullVariable.length - 2)
    }
}

export function genVariableHolder(variableName: string) {
    return '{{' + variableName + '}}';
}

var theFixedVariables: string[] = []
var theFixedVariablesWithHolder: string[] = []

export function initFixedVariables(fixedVariables: string[]) {
    theFixedVariables = [...fixedVariables]
    theFixedVariablesWithHolder = theFixedVariables.map(genVariableHolder)
}

export function getFixedVariables() {
    return theFixedVariables.slice(0)
}

export function listAllVariables(template: string): string[] {
    var result = template.match(variableRex)
    if (result) {
        return [...theFixedVariablesWithHolder, ...result]
    } else {
        return theFixedVariablesWithHolder
    }
}

export function listAllVariablesIncludeNameHolder(template: string) {

    var result = template.match(variableWithHolderRex)
    if (result) {
        return result
    } else {
        return []
    }
}

export interface VariablesDefinitions {
    variables: string[],
    variableObjects: VariableObject[]
}

export function refreshVariables(oldTemplate: string, template: string, oldVariables: string[], rows: any[]): VariablesDefinitions | null {
    //console.log("template:" + template)

    //NOTE not use split as it will also put sub group as result
    let oldTexts = oldTemplate.replace(variableRex, "___")
    let newTexts = template.replace(variableRex, "___")

    // calc variables remove duplicated
    const oldRawVariables = listAllVariables(oldTemplate)
    const rawVariables = listAllVariables(template)

    const oldVariableSet = Array.from(new Set(oldRawVariables))
    const newVariableSet = Array.from(new Set(rawVariables))

    const variableChangedFrom = oldVariableSet.filter(function (el) {
        return !newVariableSet.includes(el);
    });

    const variableChangedTo = newVariableSet.filter(function (el) {
        return !oldVariableSet.includes(el);
    });

    // console.log("", oldTexts, newTexts)
    // console.log("", variableChangedFrom, variableChangedTo)

    // filter duplicated
    const newVariables: string[] = [];

    //TODO a bug here, if COPY_NAME was there, the text will also be changed
    if (isEqual(oldTexts, newTexts) && variableChangedFrom.length == 1 && variableChangedTo.length == 1) {
        const oldVariable = (variableChangedFrom[0]);
        const newVariable = (variableChangedTo[0]);
        //only variable renamed
        console.log(" -", "only variable renamed", oldVariable, "->", newVariable)
        if (oldVariables) {
            oldVariables.forEach(v => {
                if (v == oldVariable) {
                    newVariables.push(newVariable)
                } else {
                    newVariables.push(v)
                }
            })
        }

        const oldName = inputNameExtract(oldVariable);
        const newName = inputNameExtract(newVariable);

        if (theFixedVariables.indexOf(oldName)) {
            theFixedVariables[theFixedVariables.indexOf(oldName)] = newName
            theFixedVariablesWithHolder[theFixedVariables.indexOf(oldName)] = genVariableHolder(newName)
        }

        rows.forEach(row => {
            if (row[oldName]) {
                row[newName] = row[oldName]
                delete row[oldName]
            }
        })

    } else {

        // consider the columns reordered
        if (oldVariables) {
            oldVariables.forEach(v => {
                //add existing variables at first
                if (!newVariables.includes(v) && rawVariables.includes(v)) {
                    newVariables.push(v)
                }
            })
        }

        //move new columns to the end
        rawVariables.forEach(v => {
            if (!newVariables.includes(v)) {
                newVariables.push(v)
            }
        })
    }

    if (isEqual(oldVariables, newVariables)) {
        //if variables not changed
        console.log("variables not changed")
        return null
    }

    console.log("newVariables", newVariables)

    const newVariableObjects: VariableObject[] = [];
    let variableIndex = 0

    const inputNames: string[] = [RowNo]
    newVariables.forEach(v => {
        //TODO for case, ${lastName|'1234'}  ${lastName}, no need have another index
        const variableObject: VariableObject = generateVariableObject(v, variableIndex++);
        const inputName: string = variableObject.inputName;
        if (!inputNames.includes(inputName)) {
            variableObject.dependsOnInputs.forEach(oneInput => {
                if (!inputNames.includes(oneInput)) {
                    inputNames.push(oneInput)
                    //console.log("add depend input", oneInput)
                    newVariableObjects.push(generateVariableObject(genVariableHolder(oneInput.slice(1)), variableIndex++))
                }
            })
            inputNames.push(inputName)
            newVariableObjects.push(variableObject)
        } else {
            //TODO if variableObject is already there, but missing expression, and the new one has the expression, replace it
        }

    })

    newVariableObjects.sort((a, b) => {
        return (a.allowInput? 0 : 1) -  (b.allowInput ? 0 : 1)
    })

    return {
        variables: newVariables,
        variableObjects: newVariableObjects
    }
}

export interface VariableObject {
    variableIndex: number,
    full: string,
    variableName: string,
    inputName: string,
    columnName: string,
    color: string,
    regex: RegExp,
    expression: string,
    rawExpression: string,
    dependsOnInputs: string[],
    allowInput: boolean,
    getValue(any): any
}

export function generateVariableObject(fullVariable: string, variableIndex: number): VariableObject {
    const variableName = variableNameExtract(fullVariable)
    const inputName = inputNameExtract(fullVariable)

    let expression: string = ""

    if (variableName.indexOf("|") >= 0) {
        expression = variableName.slice(variableName.indexOf("|") + 1)
    }

    const rawExpression = expression

    let allowInput = true

    if (inputName == RowNo) {
        allowInput = false
    }

    const dependsOnInputs: string[] = []

    if (expression.length > 0) {
        //TODO what if somebody want output some tag <xxx>
        const referRex = /<[0-9a-zA-Z\-_]+>/g
        const match = expression.match(referRex)
        if (match) {
            // the raw(source) be defined in the expression, like input_complex
            //let the name just for meaning of the variable
            match.forEach(e => {
                let oneInput: string = e.slice(1, e.length - 1)
                if (!oneInput.startsWith("_")) {
                    oneInput = '_' + oneInput
                }
                dependsOnInputs.push(oneInput)
                expression = expression.replace(e, "(this." + oneInput + "||'')")
            })
        }

        allowInput = false
    }

    return {
        variableIndex: variableIndex,
        full: fullVariable,
        variableName: variableName,
        inputName: '_' + inputName,
        columnName: inputName,
        color: getFixedColor(variableIndex),
        regex: new RegExp("{{" + (inputName) + "(\\|(.*?))?}}", 'g'),
        expression: expression,
        rawExpression: rawExpression,
        dependsOnInputs: dependsOnInputs,
        allowInput: allowInput,
        getValue: (row) => row['_' + inputName]
    }
}


export function preHandleTemplate(template: string): string {

    const array = template.split(PLACEHOLDER_COPY_NAME)
    if (array.length == 1) {
        return template
    }

    let newTemplate = array[0]

    let count = 0
    for (let index = 0; index < array.length; index++) {
        if (array[index].match(variableRex)) {
            count++
        }
    }

    if (count == 0) {
        return template
    }

    //if PLACEHOLDER_COPY_NAME are before/after all the variables 
    //mean only one place has variables
    if (count == 1) {
        const variables = template.match(variableRex)

        for (let index = 1; index < array.length; index++) {

            // copy from variable in the same order 
            if (variables && index - 1 < variables.length) {
                let name = inputNameExtract(variables[index - 1]);

                newTemplate += name
            }

            newTemplate += array[index]
        }
    } else {

        for (let index = 1; index < array.length; index++) {

            // COPY the nearest variable with the placeholder, first after, then before
            const variableDefinitions = array[index].match(variableRex)
            if (variableDefinitions) {
                newTemplate += inputNameExtract(variableDefinitions[0])
            } else {
                const variableDefinitions = array[index - 1].match(variableRex)
                if (variableDefinitions) {
                    newTemplate += inputNameExtract(variableDefinitions[0])
                }
            }

            newTemplate += array[index]
        }
    }


    return newTemplate
}

export interface OutputColumn {
    columnName: string,
    variableValuePrefix?: string,
    variableValueSuffix?: string
}

export function isEmptyRow(variableObjects: VariableObject[], row: any) {
    //TODO what if there is no input, all variables should be generated, and of course we need set limitation

    for (let variableObject of variableObjects) {
        if (variableObject.allowInput) {
            if (variableObject.getValue(row)) {
                return false
            }
        }
    }

    return true
}

export function updateOutputOfGivenRow(
    template: string, variableObjects: VariableObject[],
    row: any, outputColumnList: OutputColumn[]
): string {
    //console.log("update for row: ", row)

    let error = ""

    if (isEmptyRow(variableObjects, row)) {
        //console.log("empty row", row, emptyRow)
        outputColumnList.forEach(oneOutputColumn => {
            row[oneOutputColumn.columnName] = null

        })
        return error
    }

    variableObjects.forEach(variableObject => {
        //execute for expresion variable
        if (!variableObject.allowInput &&
            variableObject.expression
        ) {
            let result = ""
            try {
                //TODO consider chained dependency, like A rely on B, while B rely on C
                //console.log(" before eval", variableObject.expression, row)
                result = evalInContext(row, variableObject.expression)
                //console.log(" after eval", result)
            } catch (err: unknown) {
                if (typeof err === "string") {

                } else if (err instanceof Error) {

                    console.log(`when eval expression: ${variableObject.expression}\n`, err.name, err.message)

                    error += variableObject.columnName + " -> " + err.name + ":" + err.message + "\n"

                    result += err.name + ":" + err.message
                }
            }
            if (result) {
                row[variableObject.inputName] = result.toString()
            } else {
                row[variableObject.inputName] = null
            }
        }
    })

    outputColumnList.forEach(oneOutputColumn => {
        updateOutputForColumn(template, variableObjects, row, oneOutputColumn.columnName,
            oneOutputColumn.variableValuePrefix, oneOutputColumn.variableValueSuffix)

    })

    return error

}

function updateOutputForColumn(template: string, variableObjects: VariableObject[], row: any, outputColumn: string, variableValuePrefx: string = "", variableValueSuffix = "") {

    let output = template;

    if (!variableValuePrefx) {
        variableValuePrefx = ""
    }

    if (!variableValueSuffix) {
        variableValuePrefx = ""
    }

    //console.debug("upateOutputForColumn", row)

    //console.debug("before update:", output);


    //XXX switch own engine or open source engine 
    if (true) {
        variableObjects.forEach((variableObject) => {
            output = renderText(output, row, variableObject, variableValuePrefx, variableValueSuffix);
        })
    } else {
        //TODO turn on Handlebars
        // var templateEngine = Handlebars.compile(template);
        // output = templateEngine(row);
    }
    // console.debug("after update:", output);

    row[outputColumn] = output;

    //console.debug(row)

    return output
}

/*
SUBSTITUTE(
    SUBSTITUTE(
        SUBSTITUTE("{{date}} {{time}}", 
        "{{date}}", A1), 
    "{{time}}", B1)
)
*/
export function calculateSubstitute(newTemplateValue: string, variableObjects: VariableObject[], rownNo: string = "1") {

    let substitueExpression = "\"" + newTemplateValue.replace(/\"/g, `""`) + "\"";

    if (substitueExpression.length > 250) {
        // Text values in formulas are limited to 255 characters. To create longer text values in a formula, use the CONCATENATE function or the concatenation operator (&).
        // "abcdefg"
        //=>
        // "abcd" & "efg"

        substitueExpression = concatWhenTooLong(substitueExpression);

    }

    substitueExpression = substitueExpression.replace(/\n/g, "weiweinuonuo")

    variableObjects.forEach((vo) => {
        const variableHolder = genVariableHolder(vo.columnName);
        substitueExpression = substitueExpression.replace(vo.regex, variableHolder)
        substitueExpression = substitueExpression.replace(/\n/g, "\n  ")

        substitueExpression = "\nSUBSTITUTE(" + substitueExpression + ", \n\""
            + variableHolder + "\"" + ", " + String.fromCharCode(65 + vo.variableIndex) + rownNo + ")";

    });
    substitueExpression = substitueExpression.trim().replace(/weiweinuonuo/g, "\n");
    //console.log("substitueExpression:", substitueExpression)
    return substitueExpression;
}

function concatWhenTooLong(expression: string) {
    const list = expression.split(variableRexForSplit);
    const finalList: string[] = [];
    list.forEach(item => {
        //if single is too large
        while (item.length > 250) {
            finalList.push(item.slice(0, 250));
            item = item.slice(250);
        }
        finalList.push(item);
    });

    let newFinal = "", newTemp = "";

    finalList.forEach(item => {

        if (item.length > 250) {
        }
        if (newTemp.length + item.length < 250) {
            newTemp += item;
        } else {
            if (newFinal.length > 0) {
                newFinal = newFinal + "\" & \"" + newTemp;
            } else {
                newFinal = newTemp;
            }
            newTemp = item;
        }
    });

    if (newFinal.length > 0) {
        newFinal = newFinal + "\" & \"" + newTemp;
    } else {
        newFinal = newTemp;
    }

    expression = newFinal;
    return expression;
}

//begin {{date}}-{{time}} end
//=>
//"begin " & A1 & "-" & A2 & " end"
export function calculateConcatenate(newTemplateValue: string, variableObjects: VariableObject[], rownNo: string = "1") {
    let expression = "\"" + newTemplateValue.replace(/\"/g, `""`) + "\"";
    if (expression.length > 250) {
        expression = concatWhenTooLong(expression);
    }
    expression = expression.replace(/\n/g, "\n  ")

    variableObjects.forEach((vo) => {
        expression = expression.replace(vo.regex, "\" & " + String.fromCharCode(65 + vo.variableIndex) + rownNo + " & \"")
    });
    expression = expression.trim().replace(/weiweinuonuo/g, "\n");
    console.log("calculateConcatenate:", expression)
    return expression;
}

/**
 * {{abc}} => \{\{abc}}
 * @param raw 
 */
export function escapeVariable(raw: string): string {

    let result = raw
    result.match(variableRex_plain)?.forEach(
        (it) => {
            result = result.replace(it, it.replace("{{", "\\{\\{"))
        }
    )

    return result;
}

const escapedVariableRex_plain = /\\{\\{[0-9a-zA-Z\-_]+}}/g

/**
 * \{\{abc}} => {{abc}}
 * @param raw 
 */

function unescapeVariable(raw: string): string {

    let result = raw
    result.match(escapedVariableRex_plain)?.forEach(
        (it) => {
            result = result.replace(it, it.replace("\\{\\{", "{{"))
        }
    )

    return result;
}