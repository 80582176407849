

export const encodeTheUrl = (template: string, csvUrl?: string|null, outputLineBreaker?: boolean) => {
    //TODO add support variables
    let result = '?template=' + encodeURIComponent(template)

    if (csvUrl) {
        result = result + "&sheetUrl=" + encodeURIComponent(csvUrl)
    }
    if (outputLineBreaker === true || outputLineBreaker === false) {
        result = result + "&outputLineBreaker=" + encodeURIComponent(outputLineBreaker)
    }

    return result
}