

export const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

// make it not so bright
const getRandomColor2 = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        if (i % 2 == 0 && Math.random() < 0.66) {
            color += letters[Math.floor(Math.random() * 8)];
        } else {
            color += letters[Math.floor(Math.random() * 16)];
        }
    }
    return color;
};

const chartColors = {
    blue: 'rgb(54, 162, 235)',
    red: 'rgb(255, 99, 132)',
    yellow: 'rgb(221, 165, 34)',
    green: 'rgb(75, 192, 192)',
    orange: 'rgb(255, 159, 64)',
    // grey: 'rgb(201, 203, 207)',
    purple: 'rgb(153, 102, 255)',
};

const chartColorsList: string[] = []

for (let prop in chartColors) {
    if (Object.prototype.hasOwnProperty.call(chartColors, prop)) {
        chartColorsList.push(chartColors[prop])
    }
}

export function getFixedColor(index: number): string {
    if (index < chartColorsList.length)
        return chartColorsList[index]
    else {
        //make sure it is consistant
        const color = getRandomColor2();
        chartColorsList[index] = color
        return color
    }

}

export function transpose(matrix) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
};

export function stripHtml(html: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function occurrences(string: string, substring: string) {
    if (!string || !substring) {
        return 0
    }

    let n = 0;
    let pos = 0;

    while (true) {
        pos = string.indexOf(substring, pos);
        if (pos != -1) {
            n++;
            pos += substring.length;
        } else {
            break;
        }
    }
    return (n);
}

export function escapeRegExp(s: string) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

// evalInContext({key: true}, '  this.key ? "YES" : "NO"')

/*
export function evalInContext(context, js) {
    //# Return the results of the in-line anonymous function we .call with the passed context
    return function() {return eval(js);}.call(context);
}
*/

export function evalInContext(context: any, js: string) {
    return Function('return ' + js).call(context);
}

// const niceWords = ["awesome", "amazing", "wonderful", "fantastic", "terrific", "perfect", "phenomenal", "brillant", "splendid", "pleasant"]

export function regexIndexOf(text: string, regex: string, startpos?: number) {
    let indexOf = text.substring(startpos || 0).search(regex);
    return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
}

export function isNullOrUndefined(obj: any) {
    // return obj == null // juggling-check
    return typeof obj === 'undefined' || obj === null; // strict-check
}

export function s2ab(s: string): ArrayBuffer {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}