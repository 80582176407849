import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import './TableTemplate.css';

// import App from './App';
import TableTemplate from './TableTemplate';

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const theme = createTheme({
  palette: {
    // primary: {
    //   main: red[500],
    // },
  },
});

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <Provider template={AlertTemplate} {...options}>
    <ThemeProvider theme={theme}>
      <TableTemplate />
    </ThemeProvider>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
