import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import download from 'downloadjs';
import JSZip from 'jszip';
import Papa from 'papaparse';
import React, { useImperativeHandle, useState } from 'react';
import { useAlert } from 'react-alert';
import XLSX from 'xlsx';
import { DialogActions, DialogContent, DialogTitle } from './materialUIUtils';
import { s2ab } from './utils';


export interface ExportContext {
    outputToText: any,
    outputToSheet: any,
    calculateExcelFunction: any,
}

const context: ExportContext = {
    outputToText: null,
    outputToSheet: null,
    calculateExcelFunction: null
}

const zipDownload = (fileName: string, text: string | Blob) => {
    let zip = new JSZip();

    // Add an top-level, arbitrary text file with contents
    zip.file(fileName, text);

    // Generate the zip file asynchronously
    zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        //any level between 1 (best speed) and 9 (best compression)
        compressionOptions: {
            level: 6
        }
    })
        .then(function (content) {
            // Force down of the Zip file
            download(content, fileName + ".zip", "text/plain");
        });
}

const handleExportDialogAction = (alert, exportOption) => {

    const fileName = exportOption.fileName.length > 0 ? exportOption.fileName : new Date().toISOString().substring(0, 10)

    //firebase.analytics().logEvent(`result ${exportOption.format}`);

    if ('clipboard' == exportOption.format) {

        const { text, rows } = context.outputToText();

        alert.show(`Copied ${rows.length} Rows!`);

        navigator.clipboard.writeText(text)

    } else if ('txt' == exportOption.format) {

        const { text, rows } = context.outputToText();

        let finalFinalName = fileName;

        if (!finalFinalName.indexOf(".")) {
            finalFinalName += ".txt"
        }

        if (!exportOption.asZip) {
            download(text, finalFinalName, "text/plain");
        } else {
            zipDownload(finalFinalName, text);
        }

    } else if ('csv' == exportOption.format) {
        const rows = context.outputToSheet(exportOption.includeAllInputColumns);
        const text = Papa.unparse(rows);

        if (!exportOption.asZip) {
            download(text, fileName + ".csv", "text/plain");
        } else {
            zipDownload(fileName + ".csv", text);
        }


    } else if ('xlsx' == exportOption.format) {

        const rows = context.outputToSheet(exportOption.includeAllInputColumns);

        //this is for fix the last row not exported issue
        rows.push({})

        /* make the worksheet */
        let ws = XLSX.utils.json_to_sheet(rows);

        if (true) {
            let func = context.calculateExcelFunction("zhongquanchuji")

            const columns = Object.keys(rows[0])

            for (let index = 0; index < rows.length; index++) {
                const theFun = func.replace(/zhongquanchuji/g, `${index + 1}`);
                ws[XLSX.utils.encode_cell({ r: index, c: columns.length })] = { f: theFun };
            }

            var range = {
                s: { r: 0, c: 0 },
                e: { r: rows.length, c: columns.length }
            };

            ws['!ref'] = XLSX.utils.encode_range(range);
        }

        /* add to workbook */
        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Test");

        let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        /* generate a download */
        if (!exportOption.asZip) {
            download(new Blob([s2ab(wbout)]), fileName + ".xlsx", "application/octet-stream");
        } else {
            zipDownload(fileName + ".xlsx", new Blob([s2ab(wbout)]));
        }
    }

};

type ExportOptions = {
    asZip: boolean,
    includeAllInputColumns: boolean,
    format: string,
    fileName: string
}

export const Exporter = React.forwardRef((props, ref) => {

    const alert = useAlert()

    const [exportDialogOpen, setExportDialogOpen] = useState(false);

    const [exportOptions, setOptions] = useState<ExportOptions>({

        asZip: false,
        includeAllInputColumns: false,
        format: "clipboard",
        fileName: ""

    });


    const goToExportDialog = () => {
        setExportDialogOpen(true);
    };

    const handleExportDialogClose = () => {
        setExportDialogOpen(false);
    };

    //console.log("xxxx init again")

    //TODO https://stackoverflow.com/questions/62210286/declare-type-with-react-useimperativehandle
    useImperativeHandle(ref, () => ({
        openExport: (outputToText, outputToSheet, calculateSubstitute) => {
            context.outputToText = outputToText
            context.outputToSheet = outputToSheet
            context.calculateExcelFunction = calculateSubstitute
            goToExportDialog()
        }
    }));

    return (
        <div>

            <Dialog onClose={handleExportDialogClose} aria-labelledby="Export-dialog" open={exportDialogOpen!}>
                <DialogTitle id="Export-dialog-title"
                    onClose={handleExportDialogClose}
                >
                    Export/Download!
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>

                    </Typography>

                    <FormControl component="fieldset" style={{ width: "450px" }}>

                        <FormLabel component="legend">Export Options</FormLabel>
                        <RadioGroup aria-label="file format" name="file format" value={exportOptions.format} onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                let value = ((event.target as HTMLInputElement).value);
                                let includeInput = false;
                                if (value == 'csv' || value == 'xlsx') {
                                    includeInput = true;
                                }
                                setOptions({

                                    ...exportOptions,
                                    format: value,
                                    includeAllInputColumns: includeInput

                                })
                            }
                        }>
                            <FormControlLabel value="clipboard" control={<Radio />} label="Clipboard" />
                            <Typography align="right" >
                                only output, directly copy to clipboard
                            </Typography>
                            <FormControlLabel value="txt" control={<Radio />} label="TXT" />
                            <Typography align="right" >
                                only output, suffix as .txt
                            </Typography>
                            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                            <Typography align="right" >
                                all columns, suffix as .csv
                            </Typography>
                            <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" />
                            <Typography align="right" >
                                all columns with excel function, suffix as .xlsx
                            </Typography>
                        </RadioGroup>

                        <FormControlLabel
                            label="Include all input columns"
                            disabled
                            control={<Checkbox checked={exportOptions.includeAllInputColumns} onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    let value = event.target.checked;
                                    setOptions({

                                        ...exportOptions,
                                        includeAllInputColumns: value

                                    })
                                }
                            } color="primary" />}
                        />

                        <FormControlLabel
                            label="as Zip?"
                            control={<Checkbox checked={exportOptions.asZip} onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    let value = event.target.checked;
                                    setOptions({

                                        ...exportOptions,
                                        asZip: value

                                    })
                                }
                            } color="primary" />}
                        />

                        <TextField type="text"
                            placeholder=""
                            variant="standard"
                            label="download file name (optional)"
                            fullWidth
                            helperText=""
                            value={exportOptions.fileName} onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => {
                                    let value = event.target.value;
                                    setOptions({

                                        ...exportOptions,
                                        fileName: value

                                    })
                                }
                            }
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>

                    <Button onClick={handleExportDialogClose} color="secondary">
                        No
                    </Button>
                    <Button onClick={() => {
                        handleExportDialogAction(alert, exportOptions);
                        handleExportDialogClose();
                    }} color="primary">
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})