import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Papa from 'papaparse';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useAlert } from 'react-alert';
import XLSX from 'xlsx';
import { DialogActions, DialogContent, DialogTitle } from './materialUIUtils';
import { escapeVariable, genVariableHolder, getFixedVariables, template_default } from './renderEngine';

import CodeEditor from '@uiw/react-textarea-code-editor';
import Box from '@mui/material/Box';
import OutlinedDiv from './OutlinedDiv';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
const Handlebars = require("handlebars");

type ConvertOption = {
    format: string,
    convertTemplate: string,
    stringWrapper: string,
}

const embedConvertOptions: ConvertOption[] = [
    {
        format: "json",
        stringWrapper: "\"",
        convertTemplate: `{ 
    {{#kvs}}"{{KEY}}":{{VALUE}}{{#unless @last}},
    {{/unless}}{{/kvs}},
}
`
    },
    {
        format: "xml",
        stringWrapper: "",
        convertTemplate: `<row>
    {{#kvs}}<{{KEY}}>{{VALUE}}</{{KEY}}>{{#unless @last}}
    {{/unless}}{{/kvs}}
</row>
`
    },
    {
        format: "insert sql",
        stringWrapper: "\'",

        convertTemplate:
            `insert into table_name ({{#kvs}}{{KEY}}{{#unless @last}}, {{/unless}}{{/kvs}})
values ({{#kvs}}{{VALUE}}{{#unless @last}}, {{/unless}}{{/kvs}});
`
    },
    {
        format: "update sql",
        stringWrapper: "'",
        convertTemplate:
            `update table_name set {{#kvs}}{{#unless @first}}{{KEY}}={{VALUE}}{{#unless @last}}, {{/unless}}{{/unless}}{{/kvs}}
 where {{#kvs}}{{#if @first}}{{KEY}}={{VALUE}}{{/if}}{{/kvs}};`
    },
    {
        format: "csv",
        stringWrapper: "\"",
        convertTemplate: `{{#kvs}}{{VALUE}}{{#unless @last}},{{/unless}}{{/kvs}}`
    },
]

export interface ImportContext {
    handleTableUpload: any,
    templateChangeCallback: any,
    getTemplate: any,
}

export const Importer = React.forwardRef((props, ref) => {

    const alert = useAlert()

    const [quickStartDialogOpen, setQuickStartDialogOpen] = useState(false);
    const [loadSheetDialogOpen, setLoadSheetDialogOpen] = useState(false);
    const [convertSheetDialogOpen, setConvertSheetDialogOpen] = useState(false);
    const [sampleOutputDialogOpen, setSampleOutputDialogOpen] = useState(false);

    const [beforeCovertTemplate, setBeforeCovertTemplate] = useState<string | null>(null);
    const [sampleOutput, setSampleOutput] = useState<string | null>(null);
    const [metaTemplate, setMetaTemplate] = useState<string>(embedConvertOptions[0].convertTemplate);
    const [dataJsonArray, setDataJsonArray] = useState<any[]>([] as any[]);
    const [generatedTemplate, setGeneratedTemplate] = useState<string>("");
    const [wrapperCharForString, setWrapperCharForString] = useState<string>(embedConvertOptions[0].stringWrapper);
    const [unchecked, setUnchecked] = React.useState([] as number[]);


    const [context, setContext] = useState<ImportContext>({
        handleTableUpload: null,
        templateChangeCallback: null,
        getTemplate: null
    });

    const input_sheetUrl_ref = React.useRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({

        init: (context: ImportContext) => {
            setContext(context)
        }
        ,
        openImportDialog: () => {
            goToLoadSheetDialog()
        }
        ,
        openQuickStartDialog: () => {
            setQuickStartDialogOpen(true)
        }
        ,
        loadRemoteSheet: (url: string, callback: any) => {
            loadRemoteSheet(url, callback)
        }

    }));

    const closeAll = () => {
        setQuickStartDialogOpen(false);
        setLoadSheetDialogOpen(false);
        setConvertSheetDialogOpen(false);
        setSampleOutputDialogOpen(false);

    }

    const goToLoadSheetDialog = () => {
        setBeforeCovertTemplate(null)

        setQuickStartDialogOpen(false)
        setLoadSheetDialogOpen(true)
        setConvertSheetDialogOpen(false)

    };


    const goToSampleOutputDialog = () => {
        setQuickStartDialogOpen(false)
        setSampleOutputDialogOpen(true)

    };

    const handleQuickStartDialogClose = () => {

        closeAll();

        context.templateChangeCallback(template_default);


    };

    const handleSampleOutputDialogClose = () => {

        setSampleOutputDialogOpen(false);
        context.templateChangeCallback(sampleOutput)

    };

    const handleSampleOutputEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSampleOutput(escapeVariable(event.target.value));
    };

    const handleLoadSheetDialogClose = () => {

        closeAll();

    };

    const goToCovertSheetDialog = () => {
        setBeforeCovertTemplate(context.getTemplate())

        closeAll();

        setConvertSheetDialogOpen(true);

    };

    const handleConvertSheetDialogClose = () => {
        handleConvertSheetDialogClose0();
    }

    const handleConvertSheetDialogClose0 = (revertBack: boolean = false) => {

        if (revertBack && beforeCovertTemplate) {
            context.templateChangeCallback(beforeCovertTemplate);
        }

        setBeforeCovertTemplate(null)

        closeAll();
    };

    useEffect(() => {
        if (dataJsonArray != null) {
            updateMetaTemplate();
        }
    }, [context, dataJsonArray, wrapperCharForString]);

    const updateMetaTemplate = (theMetaTemplate: string = metaTemplate, theUnchecked: number[] = unchecked) => {

        if (metaTemplate != theMetaTemplate) {
            setMetaTemplate(theMetaTemplate)
        }

        const handleBarsTemplate = Handlebars.compile(theMetaTemplate, { noEscape: true });

        if (!dataJsonArray || !dataJsonArray[0]) {
            return;
        }

        console.log("dataJsonArray[0]", dataJsonArray[0])

        console.log("getFixedVariables()", getFixedVariables())

        //{ 
        // "kvs": [{"KEY": "A", "VALUE": "{{A}}"}, 
        // {"KEY": "B", "VALUE": "{{B}}"}]
        // }

        const kvs = {
            kvs: getFixedVariables()
                .filter((e, index) => { return theUnchecked.indexOf(index) === -1 })
                .map(e => {
                    let wrapper = ""
                    if (isNaN(dataJsonArray[0][e])) {
                        wrapper = wrapperCharForString;
                    }
                    return { KEY: e, VALUE: `${wrapper}${genVariableHolder(e)}${wrapper}` }
                })
        }

        console.log("kvs", kvs)

        let myTemplate = handleBarsTemplate(kvs)

        setGeneratedTemplate(myTemplate)

        if (context.templateChangeCallback) {
            context.templateChangeCallback(myTemplate);
        }

    }

    const handleConvertOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const convertSheetOptionIndex: string = (event.target as HTMLInputElement).value

        const option = embedConvertOptions[parseInt(convertSheetOptionIndex)]
        setWrapperCharForString(option.stringWrapper)
        updateMetaTemplate(option.convertTemplate)

    };

    const loadTableFromClipboard = () => {

        navigator.clipboard.readText()
            .then(text => {
                console.log('Pasted content: ', text);

                let firstLine = text.substring(0, text.indexOf("\n"));

                let delimiter: string|null = null

                if (firstLine.includes("\t")) {
                    delimiter = "\t"
                }

                let inputJson = Papa.parse(text, {
                    header: true,
                    delimiter: delimiter,
                }).data;

                uploadJson(inputJson);

                if (getFixedVariables().length > 0) {
                    goToCovertSheetDialog()
                } else {
                    alert.show("Load from clipboard failed", {
                        type: 'error',
                    })
                }

            })
            .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
            });

    }

    const uploadJson = (inputJson: any[], remoteUrl: string|null = null) => {
        console.log("uploadJson", inputJson)

        context.handleTableUpload(inputJson, remoteUrl);

        setDataJsonArray(inputJson);
    }

    const loadRemoteSheet = (sheetUrl: string | null = null, callback: () => void = () => { }) => {
        const remoteSheetUrl = sheetUrl || "/test.csv";
        console.log("prepare to load from", remoteSheetUrl)
        alert.show(`load sheet from ${remoteSheetUrl}`);

        fetch(remoteSheetUrl)
            .then((response) => {
                //console.log(" response", response)
                if (!response.ok) {
                    throw new Error('Network response was ' + response.statusText);
                }
                return response.arrayBuffer();
            })
            .then((arrayBuffer) => {
                // append the url with sheetUrl parameter

                if (remoteSheetUrl.endsWith(".csv")) {

                    let decoder = new TextDecoder("utf-8")
                    let text = decoder.decode(arrayBuffer)
                    const json = Papa.parse(text, {
                        header: true
                    }).data;

                    uploadJson(json, remoteSheetUrl)

                    if (callback) {
                        callback!()
                    }

                } else {

                    let workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                    let sheetNames = workbook.SheetNames;
                    let worksheet = workbook.Sheets[sheetNames[0]];
                    let json = XLSX.utils.sheet_to_json(worksheet);

                    uploadJson(json, remoteSheetUrl)

                    if (callback) {
                        callback!()
                    }

                }
            })
            .catch((error) => {
                console.error('There has been a problem with your fetch operation:', error);
                alert.show(`Failed to load error=${error.message}`, {
                    type: 'error',
                });
            });

    }

    const handleFieldSelectToggle = (value: number) => () => {
        const currentIndex = unchecked.indexOf(value);
        const newChecked = [...unchecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setUnchecked(newChecked);

        updateMetaTemplate(metaTemplate, newChecked);
    };

    return (
        <div>
            <Dialog onClose={handleQuickStartDialogClose} aria-labelledby="quickstart-dialog" open={quickStartDialogOpen!}>
                <DialogTitle id="quickstart-dialog-title"
                    onClose={handleQuickStartDialogClose}
                >
                    Quick Start!
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Start with your?
                    </Typography>
                    <Button variant="outlined" onClick={goToLoadSheetDialog} color="primary">
                        Sheet
                    </Button>
                    <Typography gutterBottom>
                        As data, then edit the output template, with view/select system defined output templates
                    </Typography>
                    <br></br>
                    <br></br>
                    <Button variant="outlined" onClick={goToSampleOutputDialog} color="primary">
                        Sample output
                    </Button>
                    <Typography gutterBottom>
                        As template, then extract variables, and upload/modify their values in the data table
                    </Typography>
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" onClick={handleQuickStartDialogClose} color="secondary">
                        Free Editor
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleSampleOutputDialogClose} aria-labelledby="SampleOutput-dialog" open={sampleOutputDialogOpen!}
                disableEnforceFocus
            >
                <DialogTitle id="SampleOutput-dialog-title"
                    onClose={handleSampleOutputDialogClose}
                >
                    Sample ouput
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ minWidth: "500px" }}>

                        <TextField style={{ width: "350px" }}
                            label="sample output"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            onChange={handleSampleOutputEdit}

                        />

                        <br />

                    </Box>

                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" onClick={handleSampleOutputDialogClose} color="primary">
                        Go
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleLoadSheetDialogClose} aria-labelledby="loadsheet-dialog" open={loadSheetDialogOpen!}
                disablePortal
                disableEnforceFocus

            >
                <DialogTitle id="loadsheet-dialog-title"
                    onClose={handleLoadSheetDialogClose}
                >
                    Load Sheet
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Load From: (for file/url, support csv or xls*)
                    </Typography>

                    <Box sx={{ minWidth: "500px" }}>
                        {/* style={{ display: 'flex', flexDirection: 'row', width: "95%" }} 
                                don't put this out of div, otherwise cannot format : Overlapping ranges are not allowed!
                                */}

                        <Button variant="outlined" onClick={loadTableFromClipboard} color="primary">
                            Clipboard
                        </Button>

                        <br />
                        <br />

                        <Button variant="outlined" component="label" color="primary">
                            Local File

                            <input className="csv-input" type="file" name="file" accept=".xls,.xlsx,.csv"
                                style={{ display: "none" }}

                                onChange={(event) => {
                                    let localFile = event!.target!.files![0]
                                    if (localFile.name.endsWith(".csv")) {
                                        Papa.parse(localFile, {
                                            complete: (result) => {
                                                uploadJson(result.data)
                                                if (getFixedVariables().length > 0) {
                                                    goToCovertSheetDialog()
                                                } else {
                                                    alert.show("Load failed", {
                                                        type: 'error',
                                                    })
                                                }
                                            },
                                            header: true
                                        });
                                    } else {
                                        let reader = new FileReader();
                                        reader.onload = (e) => {
                                            let workbook = XLSX.read(e.target!.result, { type: 'binary' });
                                            /* Get first worksheet */
                                            const wsname = workbook.SheetNames[0];
                                            const ws = workbook.Sheets[wsname];
                                            /* Convert array of arrays */
                                            const table = XLSX.utils.sheet_to_json(ws);
                                            //console.log("XXXXX table", table)
                                            uploadJson(table)
                                            if (getFixedVariables().length > 0) {
                                                goToCovertSheetDialog()
                                            } else {
                                                alert.show("Load failed", {
                                                    type: 'error',
                                                })
                                            }

                                        };
                                        reader.readAsBinaryString(localFile);
                                    }
                                }
                                }
                            />

                        </Button>

                        <br />
                        <br />

                        <Button variant="outlined"
                            onClick={() => {
                                loadRemoteSheet(input_sheetUrl_ref!.current!.value, () => {
                                    if (getFixedVariables().length > 0) {

                                        goToCovertSheetDialog()

                                        context.templateChangeCallback(null)

                                    } else {
                                        alert.show("Load failed", {
                                            type: 'error',
                                        })
                                    }
                                })

                            }

                            } color="primary">
                            Remote Url
                        </Button>

                        <br />

                        <TextField type="url"
                            inputRef={input_sheetUrl_ref}
                            placeholder="/test.csv"
                            variant="standard"
                            label="input remote url"
                            fullWidth
                            helperText="if not filled, then load the test csv"
                        />

                    </Box>


                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" onClick={handleLoadSheetDialogClose} color="primary">
                        Free Editor
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog onClose={handleConvertSheetDialogClose} aria-labelledby="loadsheet-dialog"
                open={convertSheetDialogOpen!}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="loadsheet-dialog-title"
                    onClose={handleConvertSheetDialogClose}
                >
                    Convert sheet to which format?
                </DialogTitle>
                <DialogContent dividers >

                    <Grid container spacing={0} margin={0} alignItems="top" height={"75%"}>

                        <Grid item xs={4}>
                            <OutlinedDiv label="Select Fields">

                                <List >
                                    {getFixedVariables().map((value, index) => {
                                        const labelId = `checkbox-list-label-${index}`;

                                        return (
                                            <ListItem
                                                key={value}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined} onClick={handleFieldSelectToggle(index)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={unchecked.indexOf(index) === -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`${value}`} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </OutlinedDiv>
                        </Grid>

                        <Grid item xs margin={-2} >

                            <Grid container item spacing={2} margin={0} padding={1} >

                                <Grid item xs={8}>
                                    <OutlinedDiv label={"To format:"}>
                                        <Box sx={{}}>

                                            <RadioGroup row onChange={handleConvertOptionChange}>
                                                {
                                                    embedConvertOptions.map((item, index) => (
                                                        <FormControlLabel value={index} control={<Radio />} label={item.format} />
                                                    )
                                                    )
                                                }
                                            </RadioGroup>
                                        </Box>
                                    </OutlinedDiv>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Wrapper Char for string" variant="outlined" value={wrapperCharForString}
                                        onChange={
                                            (event) => {
                                                setWrapperCharForString(event.target.value)

                                            }
                                        } />

                                </Grid>

                                <Grid item xs={12}>
                                    <Typography>
                                        Note: Edit here, you can customize how the template generated in below editor to support different format.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>

                                    <OutlinedDiv label="Meta template for template :)">

                                        <Box sx={{ minWidth: "500px" }}>

                                            <CodeEditor
                                                value={metaTemplate}
                                                language="handlebars"
                                                placeholder="Feel free to edit it (select one option in above as example)"
                                                onChange={(evn) => {
                                                    updateMetaTemplate(evn.target.value)
                                                }
                                                }
                                                padding={15}
                                                style={{
                                                    fontSize: 12,
                                                    backgroundColor: "#f5f5f5",
                                                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                }}
                                            />
                                        </Box>
                                    </OutlinedDiv>
                                </Grid>

                                <Grid item xs={12}>
                                    <OutlinedDiv label="Generated Template">
                                        <Box sx={{ minWidth: "500px" }}>

                                            <CodeEditor
                                                value={generatedTemplate}
                                                disabled={true}
                                                language="handlebars"
                                                placeholder="this is for generation"
                                                padding={15}
                                                style={{
                                                    fontSize: 12,
                                                    backgroundColor: "#f5f5f5",
                                                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                }}
                                            />
                                        </Box>
                                    </OutlinedDiv>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button variant="outlined" onClick={goToLoadSheetDialog} color="secondary">
                        Back to Load
                    </Button>
                    <Button variant="outlined" onClick={() => { handleConvertSheetDialogClose0(true) }} color="secondary">
                        No, don't change
                    </Button>
                    <Button variant="outlined" onClick={handleConvertSheetDialogClose} color="primary">
                        Looks good, Go!
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
})



